import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our BakerySwap Clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Swapping Mechanism</h4>
                <p className="pharagraph">BakerySwap is the first ever project of its kind to be hosted on the Binance Smart Chain to swap BSC based tokens with the supported wallets such as Binance chain wallet, Metamask and walletconnect.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/swapping-mechanism.png"
                alt="Swapping Mechanism"
                width={610}
              />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/liquidity-pool.png"
                alt="Liquidity Pool"
                width={530}
              />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Liquidity Pool</h4>
                <p className="pharagraph">The liquidity pool is maintained by the users who deposit funds into the pool. The users will get returns in the form of Bakeryswap Liquidity Pool (BLP) tokens along with the trade fee of all the transactions done against their liquidity pool.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Staking</h4>
                <p className="pharagraph">The users can earn more rewards by staking the BAKE tokens or other tokens without minimum requirement or a locking period. In addition NFT’s can also be earned by staking BAKE tokens.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/staking.png"
                alt="Staking"
                width={530}
              />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/security.png"
                alt="Security"
                width={530}
              />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Security</h4>
                <p className="pharagraph">An audit of the smart contract can be performed for the Bakeryswap clone script to ensure that the system is robustly built to manage user funds and investment.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Lowest fee</h4>
                <p className="pharagraph">On all transactions that take place on BakerySwap, 0.3% of the trade is taken as a fee by the platform. 83% of the fee goes to liquidity providers and the rest goes to BAKE holders, i.e those that own the native token.
                </p>
                <div className="text-left">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/lowest-fee.png"
                alt="Lowest fee"
                width={530}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures