import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';


class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              BakerySwap Clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Bakery Gallery
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Gamification
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  NFT Supermarket
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Launchpad
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Analytics Info
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Bakery Gallery
                      </h4>
                      <p className="pharagraph">It is a balanced bakery environment marketplace with the motive and mission of attracting numerous artists and collectors in one place. Users can acquire any type of NFT’s inside the bakery gallery.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/bakery-gallery.png"
                      alt="Bakery Gallery"
                      width={440}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Gamification
                      </h4>
                      <p className="pharagraph">BSC game box is a new concept in gamification specifically for $SOCCER and $POKER token holders. They also include big gaming partners like alien world, battle pets and my neighbor alice.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/gamification.png"
                      alt="Gamification"
                      width={430}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">NFT Supermarket
                      </h4>
                      <p className="pharagraph">The NFT supermarket is a phenomenal feature that helps in allocating a considerable amount of assets for trading. It extends its valuable service in various domains.Bakery Swap offers two NFT markets whereas one is for all the users and other is specially designed for the selected works, artists and celebrity drops.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/nft-supermarket.png"
                      alt="NFT Supermarket"
                      width={480}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Launchpad</h4>
                      <p className="pharagraph">A launchpad is provided which can perform two operational modes including the initial decentralized exchange by gaming modes. It includes battle pets and the blink, which offers an initial decentralized exchange offering.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/launchpad.png"
                      alt="Launchpad"
                      width={330}
                    />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Analytics Info
                      </h4>
                      <p className="pharagraph">An option for the latest update about the BNB price, transactions, number of pairs taking part within 24hrs, etc., is offered on the platform so that users can track the records and swap accordingly.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/analytics-info.png"
                      alt="Analytics Info"
                      width={370}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon