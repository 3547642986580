import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
              White Label BakerySwap Clone Script?</h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph" >The BakerySwap clone script is a pre-engineered DeFi exchange clone software that holds all the necessary features to operate a flourishing DeFi exchange in the long run. The Bakeryswap clone script enables you to start your own food-themed DeFi exchange platform over the Binance smart chain within a week or two. It is completely a DeFi-based decentralized exchange script. This clone script comes with all the essential protocols, features, and services that are available in the current prominent DeFi exchange - BakerySwap. 
            </p>
            <p className="pharagraph mb-0">The premium BakerySwap clone software operates and works 100% similarly to BakerySwap. Being a white-label DeFi exchange software, you can launch a fully branded DeFi-based DEX platform instantly in a cost-effective way. Our bug-free BakerySwap clone script comes with the latest features, add liquidity capabilities, high-end security protocols, and fully secure API integration. The Automated Market Maker service is one of the most prominent features of this clone script. As the script is devised on the Binance Smart Chain (BSC) network, your users can swap all types of BEP20 tokens securely in a smooth and hassle-free way.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    )
  }
}

export default Whatis