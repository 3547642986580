import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our BakerySwap Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Automated Market Maker
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Multi-wallet Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Multilingual support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Better gas fees
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Transaction history
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Lowest Slippage tolerance 
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Toggle Expert Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Customer Support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Customized Interface
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Admin Console
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Automated Market Maker
                        </h4>
                        <p className="pharagraph">Our bakery swap clone script comes with the tool for providing liquidity to the users which can process the trade through liquidity pools and thus eliminating the need to visit the traditional order book system
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap/automated-market-maker.png"
                        alt="Automated Market Maker"
                        width={470}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multi-wallet Integration
                        </h4>
                        <p className="pharagraph">Our Bakeryswap clone script supports all types of established wallets like metamask, binance chain and wallet connect.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/multi-wallet-integration.png" 
                        alt="Multi-wallet Integration"
                        width={363}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Multilingual support

                        </h4>
                        <p className="pharagraph">Our Bakeryswap clone script comes with multilingual features so any users around the globe can switch to their desired language and access the system in the composed manner.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/multilingual-support.png"
                        alt="Multilingual support"
                        width={286}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Better gas fees
                        </h4>
                        <p className="pharagraph">The benefit of having a reduced gas fee gives the users an opportunity to spend little amount of funds to execute the transactions.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/better-gas-fees.png"
                        alt="Better gas fees"
                        width={489}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Transaction history
                        </h4>
                        <p className="pharagraph">Using our Bakeryswap clone script, get tracked with your transaction history that includes swap, earnings, created and collected NFT’s, etc..
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/transaction-history.png"
                        alt="Transaction history"
                        width={380}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Lowest Slippage tolerance
                        </h4>
                        <p className="pharagraph">Bakeryswap offers lowest slippage tolerance of 0.1% and most probably making the transaction successful is the key feature of the product.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/lowest-slippage-tolerance.png"
                        alt="Lowest Slippage tolerance"
                        width={413}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Toggle Expert Mode
                        </h4>
                        <p className="pharagraph">The Product supports Toggle Expert mode to allow high slippage tolerances for expertised traders which can be used at their own risk.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/toggle-expert-mode.png"
                        alt="Toggle Expert Mode"
                        width={315}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Customer Support
                        </h4>
                        <p className="pharagraph">Bakeryswap does not offer customer support whereas our clone script overcomes this demerit by integrating a support module for the interaction between the admin and the users.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/customer-support.png"
                        alt="Customer Support"
                        width={360}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Customized Interface
                        </h4>
                        <p className="pharagraph">The Clone script comes with a unique UI/UX with customized interface,color theme to improve brand building, consistency and Customer Acquisition.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/customized-interface.png"
                        alt="Customized Interface"
                        width={360}
                      />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Admin Console
                        </h4>
                        <p className="pharagraph">The admin console offers the management of the transactions taking place in the platform, commission settings and a CMS panel to update the roadmap and announcement for user engagement.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/admin-console.png"
                        alt="Admin Console"
                        width={380}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures