import React from 'react'
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';



class Banner extends React.Component {

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">BakerySwap</span> Clone Script</h1>
                <p className="sub-heading">Deploy your own DeFi exchange over the Binance Smart chain with our most reliable BakerySwap clone script.</p>
                <ul>
                  <li className="pharagraph">A reliable DeFi exchange clone script.
                  </li>
                  <li className="pharagraph">Create a DeFi-based DEX platform on the BSC network.
                  </li>
                  <li className="pharagraph">360-degree information on your user trades and swaps.
                  </li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png"
                  alt="bakeryswap clone laptop front view"
                  width={876}
                />
                <div className="outder">
                  <Slider {...settings}>
                    <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/white-label-bakeryswap-clone-script-software.png"
                        alt="bakeryswap clone script slider1"
                        width={647}
                      />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/bakeryswap-clone-script.png"
                      alt="bakeryswap clone script slider2"
                      width={647}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/bakeryswap-clone.png"
                      alt="bakeryswap clone script slider3"
                      width={647}
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner