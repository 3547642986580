import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'

class WhyShould extends React.Component {

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              
              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you Develop a</span>
              DeFi exchange using a BakerySwap Clone Script?
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/bakeryswap-new/why-should-you-develop-a.png"
                  alt="DeFi exchange using a BakerySwap Clone Script"
                  width={540}
                />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph">This clone script is completely based on the trustable Binance Smart Chain(BSC) network.
                </li>
                <li className="pharagraph">The BakerySwap clone script is easily manageable as it comes with the latest admin dashboard and user console.
                </li>
                <li className="pharagraph">You can create and deploy a fully-fledged DeFi exchange like Bakeryswap within a few days.
                </li>
                <li className="pharagraph">This clone script is built with upgraded functionalities as per the current market standard.
                </li>
                <li className="pharagraph">Generate high ROI in a short time with your unique money-making strategies
                </li>
                <li className="pharagraph">It is a smart and cost-effective solution.</li>
                <li className="pharagraph">Easily operable.
                </li>
                <li className="pharagraph">Comes with secure API integration.
                </li>
                <li className="pharagraph">One of the best and trending food-themed DeFi exchange clone scripts in the market.
                </li>
                <li className="pharagraph">It is open for customization. So you can alter the key functionalities, visuals, and other add-on modules based on your business concepts.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould